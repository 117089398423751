// src/pages/ecommerce.js
import React from "react"
import "../styles/reset.css"
import "../styles/layout.css"
import "../styles/footer.css"
import "../styles/privacy.css"

export default function Privacy() {

  return (

    <div className="privacy-container">

      <h2 className="footer__privacy-title">プライバシーポリシー</h2>

      <div className="footer__privacy-block">
        <h3 className="footer__privacy-heading">
          個人情報の利用目的
        </h3>
        <p className="footer__privacy-text">
          当サイトは、お客様からご提供いただく情報を以下の目的の範囲内において利用します。
        </p>
        <ul>
          <li>ご本人確認のため</li>
          <li>お問い合わせ、コメント等の確認・回答のため</li>
          <li>メールマガジン・DM・各種お知らせ等の配信・送付のため</li>
          <li>キャンペーン・アンケート・モニター・取材等の実施のため</li>
          <li>サービスの提供・改善・開発・マーケティングのため</li>
          <li>お客さまの承諾・申込みに基づく、提携事業者・団体等への個人情報の提供のため</li>
          <li>利用規約等で禁じている行為などの調査のため</li>
          <li>その他個別に承諾いただいた目的</li>
        </ul>
      </div>

      <div className="footer__privacy-block">

        <h3 className="footer__privacy-heading">
          個人情報の管理
        </h3>
        <p className="footer__privacy-text">
          当サイトは、個人情報の正確性及び安全確保のために、セキュリティ対策を徹底し、個人情報の漏洩、改ざん、不正アクセスなどの危険については、必要かつ適切なレベルの安全対策を実施します。<br />
          当サイトは、第三者に重要な情報を読み取られたり、改ざんされたりすることを防ぐために、SSLによる暗号化を使用しております。
        </p>
      </div>

      <div className="footer__privacy-block">
        <h3 className="footer__privacy-heading">
          個人情報の第三者提供
        </h3>
        <p className="footer__privacy-text">当サイトは、以下を含む正当な理由がある場合を除き、個人情報を第三者に提供することはありません。</p>
        <ul>
          <li>ご本人の同意がある場合</li>
          <li>法令に基づく場合</li>
          <li>人の生命・身体・財産の保護に必要な場合</li>
          <li>公衆衛生・児童の健全育成に必要な場合</li>
          <li>国の機関等の法令の定める事務への協力の場合（税務調査、統計調査等）</li>
        </ul>
        <p className="footer__privacy-text">当サイトでは、利用目的の達成に必要な範囲内において、他の事業者へ個人情報を委託することがあります。</p>
      </div>

      <div className="footer__privacy-block">
        <h3 className="footer__privacy-heading">
          個人情報に関するお問い合わせ
        </h3>
        <p className="footer__privacy-text">
          開示、訂正、利用停止等のお申し出があった場合には、所定の方法に基づき対応致します。具体的な方法については、個別にご案内しますので、お問い合わせください。
        </p>
      </div>

      <div className="footer__privacy-block">
        <h3 className="footer__privacy-heading">
          アクセス解析
        </h3>
        <p className="footer__privacy-text">
          当サイトでは、サイトの分析と改善のためにGoogleが提供している「Google アナリティクス」を利用しています。<br />

          このサービスは、トラフィックデータの収集のためにCookie（クッキー）を使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません。
        </p>
      </div>

      <div className="footer__privacy-block">
        <h3 className="footer__privacy-heading">
          本プライバシーポリシーの変更
        </h3>
        <p className="footer__privacy-text">
          当サイトは、本プライバシーポリシーの内容を適宜見直し、その改善に努めます。<br />

          本プライバシーポリシーは、事前の予告なく変更することがあります。<br />

          本プライバシーポリシーの変更は、当サイトに掲載された時点で有効になるものとします。
        </p>
      </div>

      <div className="footer__privacy-button">

      </div>
    </div>

  )
}
